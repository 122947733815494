.btn-group .btn {
    margin-left: 1px !important;
}

.btn-group.show .dropdown-toggle {
    // removes the default bootstrap box shadow on activated dropdown buttons
    box-shadow: none;
}

//
// css varaible replacements
//
.btn {
    border-width: var(--width-border);
    cursor: pointer;
    overflow: hidden;
    display: inline-block;

    text-transform: var(--button-font-transform);
    font-family: var(--button-font-family);
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    border-radius: var(--button-border-radius);
    color: var(--main-font-color);

    &-sm {
        font-size: 85%;
    }

    &-lg {
        font-size: 115%;
    }
}

.btn-none {
    border-style: none;
    border: none;
    box-shadow: none;
    background-color: transparent;
}

@each $color, $value in $theme-colors {

    .btn-#{$color} {
        color: var(--color-#{$color}-contrast);
        background-color: var(--color-#{$color}-color);
        border-color: var(--color-#{$color}-color);
        transition: opacity 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        box-shadow: var(--button-border-shadow);
        padding: 0.375rem 1rem;

        .action__pulse {
          background-color: var(--color-#{$color}-contrast);
        }

        &:hover,
        &:hover {
            color: var(--color-#{$color}-contrast);
            background-color: var(--color-#{$color}-color);
            border-color: var(--color-#{$color}-color);
            opacity: .9;
            box-shadow: var(--button-hover-border-shadow);
        }

        &:focus,
        &.focus {
            box-shadow: var(--button-hover-border-shadow);
            color: var(--color-#{$color}-contrast);
            background-color: var(--color-#{$color}-color);
            border-color: var(--color-#{$color}-color);
        }

        &.disabled,
        &:disabled {
            background: var-lighten(var(--color-#{$color}-color));
            border-color: light-overlay();
            opacity: 1 !important;
            box-shadow: none !important;
            cursor: default;
        }

        &:active,
        &.active,
        .show > &.dropdown-toggle {
            color: var(--color-#{$color}-contrast) !important;
            background-color: var(--color-#{$color}-color) !important;
            border-color: var(--color-#{$color}-color) !important;
            box-shadow: var(--button-active-border-shadow);
            opacity: .9;
        }

    }

    .btn-outline-#{$color} {
        color: var(--color-#{$color}-color);
        border-color: var(--color-#{$color}-color);

        .action__pulse {
          background-color: var(--color-#{$color}-contrast);
        }

        &:hover,
        &:hover {
            color: var(--color-#{$color}-contrast);
            background-color: var(--color-#{$color}-color);
            border-color: var(--color-#{$color}-color);
        }

        &:focus,
        &.focus {
            box-shadow: var(--button-box-shadow), inset 0 0 0 2px dark-overlay();
            color: var(--color-#{$color}-color);
            border-color: var(--color-#{$color}-color);
        }

        &.disabled,
        &:disabled {
            color: var(--color-#{$color}-color) !important;
            background-color: transparent !important;
            cursor: default;
        }

        &:active,
        &.active,
        .show > &.dropdown-toggle {
            color: var(--color-#{$color}-contrast);;
            background-color: var(--color-#{$color}-color);
            border-color: var(--color-#{$color}-color);
        }
    }
}
