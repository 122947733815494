@use 'sass:math';

kui-table {
    display: flex;
    margin: var-if(var(--enable-rounded-corners), (var(--padding) / 2), 0rem);

    &.h-100 {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table-bordered,
        .table,
        .p-datatable,
        .p-datatable-scrollable {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    }
}

.p-treetable,
.p-datatable {
    position: relative;
    background: var(--table-background-color);
    color: var(--table-font-color);
    background: var(--table-background-color);

    table {
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;
        @include rounded-corners();
    }

    th {
        user-select: none;
        font-weight: 500;
    }

    td,
    th {
        padding: math.div($padding, 2.5) math.div($padding, 2);
        padding: calc(var(--padding) / 2.5) calc(var(--padding) / 2);
        background-clip: padding-box !important;
    }

    thead th,
    tbody td {
        vertical-align: middle;
    }

    thead {
        background: var(--main-border-color);

        > tr:first-child > th:first-child {
            @include rounded-corners;
        }

        > tr:last-child > th:last-child {
            @include rounded-corners;
        }

        > tr > th {
            color: var(--table-header-font-color);
            background: var(--table-header-background-color);
            border-bottom: var(--width-border) solid var(-table-header-border-color);

            font-family: var(--table-header-font-family);
            font-size: var(--table-header-font-size);
            font-weight: var(--table-header-font-weight);
            text-transform: var(--table-header-font-transform);

            a {
                color: var(--table-header-font-color);
            }
        }
    }

    tbody {
        > tr:last-child > td:first-child {
            @include rounded-corners;
        }

        > tr:last-child > td:last-child {
            @include rounded-corners;
        }

        > tr {
            font-family: var(--table-font-family);
            font-size: var(--table-font-size);
            font-weight: var(--table-font-weight);

            color: var(--table-font-color);
            background: var(--table-background-color);

            > td {
                background: inherit;
            }
        }
    }

    .p-column-resizer {
        border: 0px;
    }

    // Vertical indicator bar that appears when resizing
    .p-column-resizer-helper {
        @include active-element;
    }

    .p-highlight {
        @include active-element;

        i {
            color: var(--table-active-font-color);
        }
    }

    .p-sortable-column {
        &.p-highlight {
            @include active-element;
        }

        &:not(.p-highlight):hover {
            @include active-element;
        }
    }

    &:focus {
        outline: none;
    }

    // replace with theme icons
    .p-sortable-column-icon {
        @extend .icon;
        background-color: red;
    }

    .p-sort {
        @extend .icon-sort;
    }

    .p-sort-up {
        @extend .icon-sort-amount-up;
    }

    .p-sort-down {
        @extend .icon-sort-amount-down;
    }

    .p-datatable-wrapper {
        // We have to unset the overflow to allow the header / footer to be sticky
        overflow: unset !important;
    }

    .p-datatable-scrollable-table {
        thead {
            z-index: map-get($zindex, two);
        }
    }
    
}

.table-bordered {
    @include rounded-corners();
    border: none; // table is bordered by it's container

    thead,
    tbody {
        tr {
            th,
            td {
                border-style: solid;
                border-width: 0 var(--width-border) var(--width-border) 0;

                &:last-child {
                    border-right-width: 0;
                }
            }

            th {
                border-color: var(--table-header-border-color);
            }

            td {
                border-color: var(--table-border-color);
            }
        }
    }
}

.table-selectable {
    .p-treetable,
    .p-datatable {
        &-hoverable-rows {
            tbody > tr:not(.p-highlight):hover {
                cursor: pointer;

                color: var(--table-active-font-color);
                background: var(--table-active-background-color);

                > td,
                > th {
                    // using a border with opacity prevents crappy looking highlights
                    border-color: rgba(0, 0, 0, .1);
                    border-style: solid;
                }
            }
        }

        tbody > tr.p-highlight {
            // using a border with opacity prevents crappy looking highlights
            border-color: rgba(0, 0, 0, .1);
            background: var(--color-primary-color);
            color: var(--color-primary-contrast);
    
            > td,
            > th {
                border-color: inherit;
            }
        }
    }


}

.table-nowrap {
    td,
    th,
    &.td,
    &.th {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}